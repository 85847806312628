<template>
    <v-sheet class="pb-16">
        <!-- PC -->
        <v-sheet v-if="$vuetify.breakpoint.mdAndUp" style="padding-top:100px;">
            <v-sheet width="1274" class="mt-10 mx-auto">
              <v-btn tile outlined left large class="px-7 mb-10"
                     to="/main/cs?type=qna"
              ><span style="font-weight: bolder"
              >이전</span></v-btn>
              <v-sheet class="d-flex mx-auto" style="position: relative">
                <p class="text-h4 font-weight-bold">
                  Q&A
                </p>
                <div style="position: absolute;right: 0px">
                  <v-btn tile depressed left color="#00ABA9" dark large class="px-7 ml-4 font-weight-bold"
                         @click="modify"
                  >수정</v-btn>
                  <v-btn tile outlined left large class="px-7 ml-4"
                         @click="deleteItem"
                  ><span style="color: #ac2525;font-weight: bolder"
                  >삭제</span></v-btn>
                </div>
              </v-sheet>
              <v-divider color="#323232"></v-divider>
              <v-sheet>
                  <v-sheet class="mt-5 ml-4 d-flex">
                      <p class="text-h6"><v-icon left style="margin-bottom:6px;">mdi-alarm-light-outline</v-icon>
                        <span class="ml-4">{{selected.title}}</span>
                      </p>
                      <v-spacer></v-spacer>
                      <p class="text-subtitle-1 grey--text text--darken-2 mr-8 font-weight-bold">{{selected.writer}}</p>
                      <p class="text-subtitle-1 grey--text text--darken-2 mr-8 font-weight-bold">
                        <span v-if="selected.replied === true "> 답변</span>
                        <span v-else style="color: #9f6564">미답변</span>
                      </p>
                      <p class="text-subtitle-1 grey&#45;&#45;text text&#45;&#45;darken-2 mr-6">{{selected.registeredAt}}</p>
                      <p v-if="selected.view_count" class="text-subtitle-1 grey&#45;&#45;text text&#45;&#45;darken-2 mr-6">조회수 {{selected.view_count}}</p>
                  </v-sheet>
                  <v-divider color="#323232"></v-divider>
                  <v-sheet class="pa-6" min-height="500">
                    <v-textarea
                        auto-grow
                        rows="5"
                        row-height="70"
                        readonly
                        v-model="selected.content"
                        color="#FFFFFF"
                    ></v-textarea>
                  </v-sheet>
                <div v-if="hasReply === true"
                     style="padding-left: 25px"
                >
                  <QnAReply
                      v-for="(reply,i) in selected.nonMemberBoardReplies" :key="i"
                      v-bind:reply="reply"
                      v-on:event="eventHandler"
                  />
                </div>
              </v-sheet>
              <v-divider color="#323232"></v-divider>
            </v-sheet>
        </v-sheet>

        <!-- 모바일 -->
        <v-sheet v-if="!$vuetify.breakpoint.mdAndUp" class="pt-10 pb-16 px-4">
            <v-sheet class="mt-10 mx-auto">
              <v-btn tile outlined left class="px-7 mb-10"
                     to="/main/cs?type=qna"
              ><span style="font-weight: bolder"
              >이전</span></v-btn>
              <v-sheet class="d-flex mx-auto" style="position: relative">
                <p class="text-h5 font-weight-bold">
                  Q&A
                </p>
                <div style="position: absolute;right: 0px">
                  <v-btn tile depressed left color="#00ABA9" dark class="px-7 ml-4 font-weight-bold"
                         @click="modify"
                  >수정</v-btn>
                  <v-btn tile outlined left class="px-7 ml-4"
                         @click="deleteItem"
                  ><span style="color: #ac2525;font-weight: bolder"
                  >삭제</span></v-btn>
                </div>
              </v-sheet>
                <v-divider color="#323232"></v-divider>
              <v-sheet>
                <v-sheet class="mt-5 ml-4 d-flex">
                  <p class="text-h6 text-subtitle-1"><v-icon left style="margin-bottom:6px;">mdi-alarm-light-outline</v-icon>
                    <span class="ml-4">{{selected.title}}</span>
                  </p>
                  <v-spacer></v-spacer>
                  <div>
                    <p class="text-caption text-subtitle-1 grey--text text--darken-2 mr-8 font-weight-bold">{{selected.writer}}</p>
                    <p class="text-caption text-subtitle-1 grey--text text--darken-2 mr-8 font-weight-bold">
                      <span v-if="selected.replied === true "> 답변</span>
                      <span v-else style="color: #9f6564">미답변</span>
                    </p>
                    <p class="text-caption text-subtitle-1 grey--text text--darken-2 mr-6">{{selected.createdAt}}</p>
                    <p v-if="selected.view_count" class="text-subtitle-1 grey--text text--darken-2 mr-6">조회수 {{selected.view_count}}</p>
                  </div>
                </v-sheet>
                <v-divider color="#323232"></v-divider>
                <v-sheet class="pa-6" min-height="500">
                  <v-textarea
                      auto-grow
                      rows="5"
                      row-height="50"
                      readonly
                      v-model="selected.content"
                      color="#FFFFFF"
                  ></v-textarea>
                  <div v-if="hasReply === true"
                  >
                    <QnAReply
                        v-for="(reply,i) in selected.nonMemberBoardReplies" :key="i"
                        v-bind:reply="reply"
                    />
                  </div>
                </v-sheet>
              </v-sheet>
                <v-divider color="#323232"></v-divider>
            </v-sheet>
        </v-sheet>
      <Confirm
          ref="confirm"
      />
    </v-sheet>
</template>
<script>

import {BoardApi} from '../../api/BoardApi'
import Confirm from './dialog/Confirm'
import QnAReply from './QnAReply'
import {isEmpty} from "@/lib/CommUtil";

export default {
  components:{
    Confirm,
    QnAReply,
  },
    data: () => ({
      selected: {},
    }),

    mounted(){
      console.log('getMemberQuestion authenticated',this.$route.query.authenticated)
      if(this.$route.query.authenticated === '1'){
        BoardApi.getMemberQuestion(this.$route.query.id).then( res =>{
          if(res.resCode ==='0000'){
            console.log('getMemberQuestion',res)
            this.selected=res.data
            this.$store.commit('setQnAItem',this.selected)
          }else{
            alert(res.msg)
          }
        }).catch((data)=>{
          console.log('err',data)
          if(data.data.resCode ==='0412'){
            alert('세션만료입니다. 다시 인증해야 합니다')
            this.$router.push('/main/cs?type=qna')
            return
          }
          if(!isEmpty(data.data.msg)){
            alert(data.data.msg)
          }else{
            alert('글읽기 요청이 실패하였습니다')
          }
        })
        return
      }
      BoardApi.getNonMemberQuestion({
        questionId:this.$route.query.id,
        key:this.$route.query.key,
      }).then( res =>{
        if(res.resCode ==='0000'){
          console.log('getNonMemberQuestion',res)
          this.selected=res.data
          this.$store.commit('setQnAItem',this.selected)
        }else{
          alert(res.msg)
        }
      }).catch((data)=>{
        console.log('err',data)
        if(data.data.resCode ==='0412'){
          alert('세션만료입니다. 다시 인증해야 합니다')
          this.$router.push('/main/cs?type=qna')
          return
        }
        if(!isEmpty(data.data.msg)){
          alert(data.data.msg)
        }else{
          alert('글읽기 요청이 실패하였습니다')
        }
      })
    },
    computed:{
      hasReply(){
        if(isEmpty(this.selected.nonMemberBoardReplies)){
          return false
        }
        return this.selected.nonMemberBoardReplies.length > 0
      }
    },
    methods: {
      load(){
        if(this.$route.query.authenticated === '1'){
          BoardApi.getMemberQuestion(this.$route.query.id).then( res =>{
            if(res.resCode ==='0000'){
              console.log('getMemberQuestion',res)
              this.selected=res.data
              this.$store.commit('setQnAItem',this.selected)
            }else{
              alert(res.msg)
            }
          }).catch((data)=>{
            console.log('err',data)
            if(data.data.resCode ==='0412'){
              alert('세션만료입니다. 다시 인증해야 합니다')
              this.$router.push('/main/cs?type=qna')
              return
            }
            if(!isEmpty(data.data.msg)){
              alert(data.data.msg)
            }else{
              alert('글읽기 요청이 실패하였습니다')
            }
          })
          return
        }
        BoardApi.getNonMemberQuestion({
          questionId:this.$route.query.id,
          key:this.$route.query.key,
        }).then( res =>{
          if(res.resCode ==='0000'){
            console.log('getNonMemberQuestion',res)
            this.selected=res.data
            this.$store.commit('setQnAItem',this.selected)
          }else{
            alert(res.msg)
          }
        }).catch((data)=>{
          console.log('err',data)
          if(data.data.resCode ==='0412'){
            alert('세션만료입니다. 다시 인증해야 합니다')
            this.$router.push('/main/cs?type=qna')
            return
          }
          if(!isEmpty(data.data.msg)){
            alert(data.data.msg)
          }else{
            alert('글읽기 요청이 실패하였습니다')
          }
        })
      },
      modify(){
        if(this.$route.query.authenticated === '1'){
          this.$router.push({name:'qa-modify', query:{
              id:this.$route.query.id,
              authenticated:'1',
            }})
          return
        }
        this.$router.push({name:'qa-modify', query:{
          id:this.$route.query.id,
            key:this.$route.query.key,
          }})
      },
      deleteItem(){
        if(this.$route.query.authenticated === '1'){
          BoardApi.deleteMemberQuestion(this.$route.query.id).then( () =>{
            this.$router.push({name:'cs', query:{
                type:'qna',
              }})
          }).catch((data)=>{
            console.log('err',data)
            if(data.data.resCode ==='0412'){
              alert('세션만료입니다. 다시 인증해야 합니다')
              this.$router.push('/main/cs?type=qna')
              return
            }
            if(!isEmpty(data.data.msg)){
              alert(data.data.msg)
            }else{
              alert('글읽기 요청이 실패하였습니다')
            }
          })
          return
        }
        this.$refs.confirm.open((ret)=>{
          if(ret === true){
            BoardApi.deleteNonMemberQuestion({
              questionId:this.$route.query.id,
              key:this.$route.query.key,
            }).then( () =>{
              this.$router.push({name:'cs', query:{
                  type:'qna',
                }})
            }).catch((data)=>{
              console.log('err',data)
              if(data.data.resCode ==='0412'){
                alert('세션만료입니다. 다시 인증해야 합니다')
                this.$router.push('/main/cs?type=qna')
                return
              }
              if(!isEmpty(data.data.msg)){
                alert(data.data.msg)
              }else{
                alert('글읽기 요청이 실패하였습니다')
              }
            })
          }
        })
      },
      eventHandler(){
        this.load()
      }
    }
}
</script>
<style>

</style>