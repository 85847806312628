<template>
  <div>
    <v-sheet>
      <v-sheet class="mx-auto migration-qa">
        <div class="migration-title">개인 및 이전 상담</div>
        <v-divider color="#424242"></v-divider>
        <v-sheet>
          <p class="migration-info">
            * 사용문의 , 요청사항이 있으시면<br v-if="$vuetify.breakpoint.smAndDown" /> 언제든 문의주세요.<br />
            * 타사 서비스를 이용하시는 분들 중 위셀페이 이전을 고민하시는 분들은 상담 문의를 남겨주세요.<br />
            위셀페이에서 필요한 기술지원을 해 드립니다.
          </p>
        </v-sheet>
        <v-divider color="#424242"></v-divider>
        <v-sheet class="mx-auto info-collection">
          <v-sheet class="info-collection-confirm">
            <p>
              개인정보 수집 및 이용 동의를 거부할 경우 상담 게시글을 작성할 수 없습니다.
            </p>
            <v-checkbox
                v-model="checkbox"
                label="[필수] 개인정보 수집 및 이용동의"
            ></v-checkbox>
            <div class="collection-information">
              <span class="info-collection-subtitle">수집 및 이용 목적</span>
              <span class="info-collection-text">상담의 원활한 의사소통 경로 확보, 상담 및 처리결과 안내</span>
            </div>
            <div class="collection-information">
              <span class="info-collection-subtitle">수집 및 이용 항목</span>
              <span class="info-collection-text">회사명/상호명, 담당자명, 연락처, 이메일</span>
            </div>
            <div class="collection-information">
              <span class="info-collection-subtitle">보유 및 이용 기간</span>
              <span class="info-collection-text">이용 목적 달성 시 즉시 파기(단, 관계법령에 따라 보관이 필요한 경우 해당기간동안 보관함. 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 보관)</span>
            </div>
          </v-sheet>
          <v-divider class="table-divider" color="#424242"></v-divider>
          <v-sheet rounded class="info-input d-flex">
            <span>성명/상호명 *</span>
            <v-text-field
                placeholder="성명 또는 상호명을 입력해주세요"
                required
                v-model="writer"
                @change="$v.writer.$touch()"
                @blur="$v.writer.$touch()"
                :error-messages="writer_Errors"
            ></v-text-field>
          </v-sheet>
          <v-sheet rounded class="info-input d-flex">
            <span>핸드폰 *</span>
            <v-text-field
                placeholder="핸드폰 번호는 필수 입력 입니다"
                dense
                color="brown"
                required
                :error-messages="mobilePhone_Errors"
                @change="$v.mobilePhone.$touch()"
                @blur="$v.mobilePhone.$touch()"
                v-model="mobilePhone"
            ></v-text-field>
          </v-sheet>
          <v-sheet rounded class="info-input d-flex">
            <span>이메일 *</span>
            <v-text-field
                placeholder="이메일은 필수 입력 입니다"
                dense
                color="brown"
                required
                :error-messages="email_Errors"
                @change="$v.email.$touch()"
                @blur="$v.email.$touch()"
                v-model="email"
            ></v-text-field>
          </v-sheet>
          <v-sheet rounded class="info-input d-flex">
            <span>문의유형 *</span>
            <v-select
                :items="items"
                v-model="qnaType"
                dense
                placeholder="문의는 필수 입력 입니다."
                append-icon=""
            ></v-select>
          </v-sheet>
          <v-sheet rounded class="info-input d-flex">
            <span>제목 *</span>
            <v-text-field
                placeholder="제목을 입력해주세요"
                dense
                :error-messages="title_Errors"
                @change="$v.title.$touch()"
                @blur="$v.title.$touch()"
                v-model="title"
                required
            ></v-text-field>
          </v-sheet>
          <v-sheet rounded class="info-input d-flex">
            <span class="textarea-input">내용 *</span>
            <v-textarea
                filled
                auto-grow
                placeholder="내용을 입력해주세요"
                rows="5"
                row-height="50"
                :error-messages="content_Errors"
                @change="$v.content.$touch()"
                @blur="$v.content.$touch()"
                v-model="content"
            ></v-textarea>
          </v-sheet>
          <v-sheet class="mt-10 mx-auto align-center text-center">
            <v-btn tile depressed color="#00ABA9" dark class="save-button"
                    @click="save"
                    :loading="loading"
            >저장</v-btn>
          </v-sheet>
          <v-sheet rounded class="work-time" >
            <div>
              고객센터 업무시간 : 평일<br v-if="$vuetify.breakpoint.smAndDown"> 09:00~18:00(주말, 공휴일 휴무)<br>
              상담요청 후 기입하신 이메일이나 문자로<br v-if="$vuetify.breakpoint.smAndDown"> 연락을 보내드립니다.
              <p class="mt-5 text-caption-2 pa-2">
                ※ 고객센터 업무시간 외에 접수된 문의사항은 문의 유형/내용/접수량에 따라 답변이 지연될 수 있습니다.<br>
              </p>
            </div>
          </v-sheet>
        </v-sheet>
      </v-sheet>
    </v-sheet>
  </div>
</template>

<script>
const {BoardApi} = require("@/api/BoardApi");
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength,email ,numeric} from 'vuelidate/lib/validators'

export default {
  name: "MigrationQa",
  mixins: [validationMixin],

  validations: {
    writer: {
      required,
      maxLength: maxLength(20),
    },
    title: {
      required,
      maxLength: maxLength(128),
    },
    content: {
      required,
    },
    mobilePhone: {
      required,
      numeric,
      minLength: minLength(10),
    },
    email: {
      required,
      email,
    },
  },
  computed: {
    writer_Errors () {
      const errors = []
      if (!this.$v.writer.$dirty) return errors
      !this.$v.writer.required && errors.push('상호명 혹은 성명을 입력해 주세요')
      !this.$v.writer.maxLength && errors.push('최대 20자 이내이여야합니다')
      return errors
    },
    mobilePhone_Errors () {
      const errors = []
      if (!this.$v.mobilePhone.$dirty) return errors
      !this.$v.mobilePhone.required && errors.push('핸드폰번호를 입력해주세요')
      !this.$v.mobilePhone.numeric && errors.push('숫자로만 입력해 주세요.')
      !this.$v.mobilePhone.minLength && errors.push('핸드폰번호를 최소 10자 이상이여야합니다')
      return errors
    },
    email_Errors () {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.required && errors.push('이메일을 입력해 주세요.')
      !this.$v.email.email && errors.push('이메일 형식으로 입력해주세요.')
      return errors
    },
    title_Errors () {
      const errors = []
      if (!this.$v.title.$dirty) return errors
      !this.$v.title.required && errors.push('제목을 입력해주세요')
      !this.$v.title.maxLength && errors.push('최대 128자 이내이여야합니다')
      return errors
    },
    content_Errors () {
      const errors = []
      if (!this.$v.content.$dirty) return errors
      !this.$v.content.required && errors.push('내용을 입력해주세요')
      return errors
    },
  },
  data(){
    return {
      writer:'',
      title:'',
      content:'',
      mobilePhone:'',
      email:'',
      loading:false,
      checkbox:false,
      items: [{
              text: '이전상담',
              value: 'MIGRATION',
            },{
              text: '데이터이전',
              value: 'DATA_MIGRATION',
            } ,{
              text: '사용문의',
              value: 'USAGE_QA',
            } ,{
              text: '요청사항',
              value: 'REQUEST',
            } ,
            {
              text: '기타',
              value: 'ETC',
            } ,
      ],
      qnaType:'',
    }
  },
  methods:{
    save(){
      this.$v.$touch()
      if(this.checkbox === false){
        alert('개인정보 수집 및 이용에 대한 동의가 필요합니다.')
        return;
      }
      if(this.qnaType ===''){
        alert('문의유형을 선택해 주세요.')
        return;
      }
      console.log('save',this.qnaType )
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
        alert('비어있는 입력란이 있습니다.')
        return
      }
      const request ={
        name:this.writer,
        mobilePhone:this.mobilePhone,
        email:this.email,
        migrationQnaType:this.qnaType,
        title:this.title,
        content:this.content,
      }
      this.loading=true
      BoardApi.postNonMemberMigrationQuestion(request).then(()=>{
        alert('등록성공입니다. 빠른 답변 드리도록 하겠습니다.')
        this.loading=false
        this.reset()
      }).catch(()=>{
        alert('등록 실패입니다. 문제가 지속되면 관리자에게 문의해 주세요')
        this.loading=false
      })
    },
    reset(){
      this.writer=''
      this.title=''
      this.content=''
      this.mobilePhone=''
      this.email=''
      this.qnaType=''
      this.checkbox=false
      this.$v.$reset()
    }
  }

}
</script>

<style scoped lang="scss">
  @import "../../styles/migration_qa.scss";

</style>
