<template>
    <v-sheet>
        <!-- PC -->
        <v-sheet class="introduce-order">
        <v-sheet class="first-sheet">
          <div>
            <div class="first-sheet-title">모바일/PC로 어디서든 <br/>
              <span>빠르게 주문확인</span>
            </div>
            <div class="first-sheet-subtitle">휴대폰으로 어디서든 간편하게 확인하고 처리가 가능합니다.<br v-if="$vuetify.breakpoint.xsOnly" /> 쉽고 빠르고 언제 어디서든 주문확인 및 처리가 가능합니다!</div>
          </div>
          <div class="explanation-image">
            <img src="../../../assets/introduce/order/desktop_frame.png" />
            <img src="../../../assets/introduce/order/desktop_shadow.png" />
            <img src="../../../assets/introduce/order/desktop_order.png" />
            <img src="../../../assets/introduce/order/mobile_frame.png" />
            <img src="../../../assets/introduce/order/mobile_order.png" />
          </div>
        </v-sheet>
        <v-sheet class="second-sheet">
          <div>
            <div class="second-sheet-title"><span>다양한 주문 검색</span>으로<br v-if="$vuetify.breakpoint.xsOnly" /> 간편하게 확인</div>
            <div class="second-sheet-subtitle" v-if="!$vuetify.breakpoint.xsOnly">
              PC/모바일 구분없이 언제 어디서든 쉽고 간편하게 주문을 확인하실 수 있습니다.<br />
              또한 상품명, 상품번호, 주문자 등 다양한 검색 방법으로<br />
              빠르게 주문을 확인하실 수 있습니다.
            </div>
            <div class="second-sheet-subtitle" v-if="$vuetify.breakpoint.xsOnly">
              PC/모바일 구분없이 언제 어디서든 쉽고<br /> 간편하게 주문을 확인하실 수 있습니다.<br />
              또한 상품명, 상품번호, 주문자 등 다양한 검색<br /> 방법으로
              빠르게 주문을 확인하실 수 있습니다.
            </div>
          </div>
          <div class="explanation-image">
            <img src="../../../assets/introduce/order/desktop_mobile_order.png" />
            <img src="../../../assets/introduce/order/order_detail.png" />
          </div>
        </v-sheet>
        <v-sheet class="third-sheet">
          <div v-if="!$vuetify.breakpoint.xsOnly">
            <div class="third-sheet-title">주문상품 항목별 확인 및 현황파악</div>
            <div class="third-sheet-subtitle">
              전체, 입금확인 중, 신규주문 등 항목별로 정리된 리스트로 확인<br />
              가능하여 다음 프로세스로 진행하기 쉽습니다.
            </div>
          </div>
          <div v-if="$vuetify.breakpoint.xsOnly">
            <div class="third-sheet-title">주문상품 항목별 확인<br /> 및 현황파악</div>
            <div class="third-sheet-subtitle">
              전체, 입금확인 중, 신규주문 등 항목별로 정리된 리스트로 확인 가능하여 다음 프로세스로 진행하기 쉽습니다.
            </div>
          </div>
          <div class="centered-image">
            <img src="../../../assets/introduce/order/order_search.png" />
            <img src="../../../assets/introduce/order/order_list.png" />
          </div>
          <div class="explanation-image">
            <img src="../../../assets/introduce/order/order_menu.png" />
            <img v-if="!$vuetify.breakpoint.xsOnly" src="../../../assets/introduce/order/click_target1.png" />
            <img v-if="$vuetify.breakpoint.xsOnly" src="../../../assets/introduce/order/mb_click_target1.png" />
            <div></div>
            <div></div>
            <img src="../../../assets/introduce/order/order_management_button_group.png" />
            <img v-if="!$vuetify.breakpoint.xsOnly" src="../../../assets/introduce/order/click_target2.png" />
            <img v-if="$vuetify.breakpoint.xsOnly" src="../../../assets/introduce/order/mb_click_target2.png" />
          </div>
        </v-sheet>
        <v-sheet class="fourth-sheet">
          <div>
            <div class="fourth-sheet-title">
              주문 옵션별 엑셀 다운로드로<br />
              편하게 주문확인
            </div>
            <div class="fourth-sheet-subtitle">
              주문관리에서 주문 다운로드 엑셀옵션을 설정하면 다양한 형태의 <br v-if="!$vuetify.breakpoint.xsOnly"/>
              정보로 다운로드가 가능합니다.
            </div>
          </div>
          <div class="explanation-image">
            <img src="../../../assets/introduce/order/blurred_order_list.png" />
            <div v-if="!$vuetify.breakpoint.xsOnly" class="blurred-background"></div>
            <img src="../../../assets/introduce/order/excel_button_group.png" />
            <img src="../../../assets/introduce/order/rounded_1.png" />
            <img src="../../../assets/introduce/order/left_up_arrow.png" />
            <span>click!</span>
            <img src="../../../assets/introduce/order/download_confirm.png" />
            <img src="../../../assets/introduce/order/rounded_2.png" />
            <img src="../../../assets/introduce/order/right_down_arrow.png" />
            <span>click!</span>
            <img src="../../../assets/introduce/order/excel_sheet.png" />
            <img src="../../../assets/introduce/order/rounded_3.png" />
          </div>
        </v-sheet>
      </v-sheet>
    </v-sheet>
</template>
<script>

import MainFunctionView from "../main/MainFunctionView";
import MainFunctionView2 from "@/views/page/main/MainFunctionView2";

export default {
  components:{
    MainFunctionView2,
    MainFunctionView
  },
  data(){
    return {
      tabIndex:0
    }
  }
}
</script>
<style lang="scss" scoped>
  @import '../../../styles/introduce/order/order-first.scss';
  @import '../../../styles/introduce/order/order-second.scss';
  @import '../../../styles/introduce/order/order-third.scss';
  @import '../../../styles/introduce/order/order-fourth.scss';
</style>