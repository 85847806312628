<template>
    <v-sheet class="pb-8">
      <!-- PC -->
      <v-sheet class="cs-page">
        <v-tabs
          color="rgba(0, 74, 131, 1)"
          next-icon="mdi-arrow-right"
          prev-icon="mdi-arrow-left"
          show-arrows
          hide-slider
          centered
          v-model="tabIndex"
          @change="tabChanged"
        >
          <v-tab color="#EFEBE9" key="notice">
            <label>공지사항</label>
          </v-tab>
<!--          <v-tab color="#EFEBE9" key="qna">
            <label>Q&A</label>
          </v-tab>-->
          <v-tab color="#EFEBE9" key="migration">
            <label>상담</label>
          </v-tab>
          <v-tab color="#EFEBE9" key="faq">
            <label>FAQ</label>
          </v-tab>
        </v-tabs>
        <v-sheet class="mx-auto cs-main-contents" v-if="board_type==='notice' || board_type ==='qna'">
          <cs_info
              v-bind:boardType="board_type"
          />
          <v-data-table
            v-if="!$vuetify.breakpoint.smAndDown"
            class="cs_table"
            :headers="headers"
            :items="items"
            :loading="loading"
            loading-text="데이터를 불러오는 중입니다..."
            hide-default-footer
          >
            <template v-slot:item="{ item }">
              <tr style="cursor:pointer;" @click="goToReadNotice(item)" v-if="board_type==='notice'">
                <td :style="$vuetify.breakpoint.lgAndUp ? $vuetify.breakpoint.xlOnly ? {width:'191px'} : {width:'9.948vw'} : {width:'7.813vw'}"
                    v-if="item.fixed"
                ><span style="border: 1px solid gainsboro;background-color: #a83218;color: white;padding: 2px 4px">공지</span></td>
                <td :style="$vuetify.breakpoint.lgAndUp ? $vuetify.breakpoint.xlOnly ? {width:'191px'} : {width:'9.948vw'} : {width:'7.813vw'}"
                    v-else
                ><span style="border: 1px solid gainsboro;background-color: #1D1D1D;color: white;padding: 2px 4px">공지</span></td>
                <td :style="$vuetify.breakpoint.lgAndUp ? $vuetify.breakpoint.xlOnly ? {width:'554px'} : {width:'28.854vw'} : {width:'31.250vw'}">{{item.title}}</td>
                <td :style="$vuetify.breakpoint.lgAndUp ? $vuetify.breakpoint.xlOnly ? {width:'253px'} : {width:'13.177vw'} : {width:'10.417vw'}">관리자</td>
                <td>{{item.createdAt}}</td>
              </tr>
              <tr style="cursor:pointer;" @click="goToReadQA(item)" v-if="board_type==='qna'">
                <td :style="$vuetify.breakpoint.lgAndUp ? $vuetify.breakpoint.xlOnly ? {width:'110px'} : {width:'5.729vw'} : {width:'6.250vw'}">Q</td>
                <td :style="$vuetify.breakpoint.lgAndUp ? $vuetify.breakpoint.xlOnly ? {width:'558px'} : {width:'29.063vw'} : {width:'26.042vw'}"><v-icon small left>mdi-lock-outline</v-icon>비공개 게시글입니다.</td>
                <td :style="$vuetify.breakpoint.lgAndUp ? $vuetify.breakpoint.xlOnly ? {width:'183px'} : {width:'9.531vw'} : {width:'9.375vw'}">{{item.writer}}</td>
                <td :style="$vuetify.breakpoint.lgAndUp ? $vuetify.breakpoint.xlOnly ? {width:'148px'} : {width:'7.708vw'} : {width:'9.375vw'}">
                  <span v-if="item.replied === true">답변</span>
                  <span v-else>미답변</span>
                </td>
                <td>{{item.registeredAt}}</td>
              </tr>
            </template>
            <template v-slot:no-data>
              <div style="line-height: 100px">
                <span style="font-weight: bold;font-size: 15px">게시글이 없습니다.</span>
              </div>
            </template>
          </v-data-table>
          <v-data-table
            v-if="$vuetify.breakpoint.smAndDown"
            class="cs_table"
            :headers="headers2"
            :items="items"
            :loading="loading"
            loading-text="데이터를 불러오는 중입니다..."
            hide-default-footer
            disable-sort
            mobile-breakpoint="0"
          >
            <template v-slot:item="{ item }">
              <tr style="cursor:pointer;" @click="goToReadNotice(item)" v-if="board_type==='notice'">
                  <td v-if="item.fixed"><span style="border: 1px solid gainsboro;background-color: #a83218;color: white;padding: 2px 4px">공지</span></td>
                  <td v-else><span style="border: 1px solid gainsboro;background-color: #1D1D1D;color: white;padding: 2px 4px">공지</span></td>
                  <td>{{item.title}}<div>관리자</div></td>
                  <td>{{item.createdAt}}</td>
              </tr>
              <tr style="cursor:pointer;" @click="goToReadQA(item)" v-if="board_type==='qna'">
                <td>Q</td>
                <td><v-icon small left>mdi-lock-outline</v-icon>비공개 게시글입니다.<div>{{item.writer}}</div></td>
                <td>
                  <span v-if="item.replied === true">답변</span>
                  <span v-else>미답변</span>
                </td>
              </tr>
            </template>
            <template v-slot:no-data>
              <div style="line-height: 100px">
                <span style="font-weight: bold;font-size: 15px">게시글이 없습니다.</span>
              </div>
            </template>
          </v-data-table>
            <!-- 페이지 -->
            <v-pagination
                color="#222222"
                circle
                next-aria-label="next-label"
                previous-aria-label="prev-label"
                v-model="page"
                :length="page_length"
                @input="pageChanged"
            ></v-pagination>
        </v-sheet>
        <MigrationQa v-if="board_type ==='migration'" />
        <CsFaq v-if="board_type ==='faq'" />
      </v-sheet>
      <QnAAuth ref="qnAAuth" />
    </v-sheet>
</template>
<script>
const {BoardApi} = require("@/api/BoardApi");
import QnAAuth from './dialog/QnAAuth';
import cs_info from './cs/cs_info';
import MigrationQa from './migration_qa';
import CsFaq from './faq/cs_faq';
import { useDisplay } from 'vuetify'

export default {
  components:{
    QnAAuth,
    MigrationQa,
    cs_info,
    CsFaq
  },
  created() {
  },
  data: () => ({
        tabIndex:0,
        headers: [
            {text: '구분', value: 'id'},
            {text: '제목', value: 'title', width: ''},
            {text: '작성자', value: 'writer'},
            {text: '작성일', value: 'created'},
        ],
        headers2: [
          {text: '구분', value: 'id', width: '14.722vw'},
          {text: '제목/작성자', value: 'title', width: '53.889vw'},
          {text: '작성일', value: 'created'},
        ],
        notice_headers: [
            {text: '구분', value: 'id'},
            {text: '제목', value: 'title'},
            {text: '작성자', value: 'writer'},
            {text: '작성일', value: 'created'},
        ],
        notice_headers2: [
          {text: '구분', value: 'id', width: '14.722vw'},
          {text: '제목/작성자', value: 'title', width: '53.889vw'},
          {text: '작성일', value: 'created'},
        ],

        qna_headers: [
            {text: 'Q&A', value: 'QnA'},
            {text: '제목', value: 'title'},
            {text: '작성자', value: 'writer'},
            {text: '답변여부', value: 'is_answered'},
            {text: '작성날짜', value: 'created'},
        ],

        qna_headers2: [
          {text: 'Q&A', value: 'QnA', width: '14.722vw'},
          {text: '제목/작성자', value: 'title', width: '53.889vw'},
          {text: '답변여부', value: 'is_answered'},
        ],
        items: [],
        board_type:"notice",
        page: 1,
        page_length: 1,
        loading:false,
        authenticated:false,

  }),

    mounted(){
        if(this.$route.query.type){
          if(this.$route.query.type ==='notice'){
            this.tabIndex=0
            this.board_type='notice'
          }
          /*if(this.$route.query.type ==='qna'){
            this.tabIndex=1
            this.board_type='qna'
          }*/
          if(this.$route.query.type ==='migration'){
            this.tabIndex=1
            this.board_type='migration'
          }
          if(this.$route.query.type ==='faq'){
            this.tabIndex=2
            this.board_type='faq'
            return
          }
          this.loadBoardPost(this.$route.query.type)

        }else{
          this.tabIndex=0
          this.headers=this.notice_headers
          this.headers2=this.notice_headers2
          this.$router.replace({query: { type: this.board_type }})
          this.loadNotice()
        }
      this.authenticated = this.$store.getters.isAuthenticated
    },

    watch: {
        board_type(){
            if(this.board_type==="notice"){
                this.headers=this.notice_headers
                this.headers2=this.notice_headers2
            }else if(this.board_type==="qna"){
              this.headers=this.qna_headers
              this.headers2=this.qna_headers2
            }
        }
    },

    methods: {
      tabChanged(value){
        if(value === 0){
          if(this.board_type === 'notice'){
            return
          }
          this.board_type='notice'
          this.$router.replace({query: { type: this.board_type }})
          //this.loadBoardPost('notice')
        }
        /*if(value === 1){
          if(this.board_type === 'qna'){
            return
          }
          this.board_type='qna'
          this.$router.replace({query: { type: this.board_type }})
          //this.loadBoardPost('qna')
        }*/
        if(value === 1){
          if(this.board_type === 'migration'){
            return
          }
          this.board_type='migration'
          this.$router.replace({query: { type: this.board_type }})
          //this.loadBoardPost('migration')
        }
        if(value === 2){
          if(this.board_type === 'faq'){
            return
          }
          this.board_type='faq'
          this.$router.replace({query: { type: this.board_type }})
        }
      },
      goToReadNotice(item){
        this.$router.push('/main/cs/read?id='+item.id)
      },
      goToReadQA(item){
        if(this.authenticated === true){
          const myInfo =  this.$store.getters.getMyInfo
          if(item.memberId === myInfo.id){
            this.$router.push('/main/qa/read?id='+item.id+'&authenticated=1')

            return
          }
        }
        this.$refs.qnAAuth.open(item.id,(ret)=>{
          if(ret.res === true){
            this.$refs.qnAAuth.close()
            this.$router.push('/main/qa/read?id='+item.id+'&key='+ret.resData)
          }else{
            alert('요청 실패입니다. 다시 시도해 주세요')
          }
        })
      },

      loadQA(){
        const page = {
          size : 10,
          page : 0,
        }
        this.loading=true
        BoardApi.getNonMemberQuestions(page).then(res=>{
          console.log('getNonMemberQuestions',res)
          this.page = res.page+1
          this.page_length=res.totalPages
          this.items=res.list.map((item) => {
            const time = item.registeredAt.replace(/-/g, "/")
            const rawDate = new Date(time);
            const year = rawDate.getFullYear().toString().slice(2);
            const month = (rawDate.getMonth() + 1).toString().padStart(2, '0');
            const date = rawDate.getDate().toString().padStart(2, '0');
            const registeredAt = `${year}-${month}-${date}`;
            return { ...item, registeredAt };
          })
          this.loading=false
        }).catch(()=>{
          alert('알수 없는 에러가 발생하였습니다')
          this.loading=false
        })
      },
      loadNotice(){
        const page = {
          size : 10,
          page : 0,
        }
        this.loading=true
        BoardApi.getAdminNotices(page).then(res=>{
          console.log('loadNotice', res);
          this.page = res.page+1
          this.page_length=res.totalPages
          this.items=res.list.map((item) => {
            const time = item.createdAt.replace(/-/g, "/")
            const rawDate = new Date(time);
            const year = rawDate.getFullYear().toString().slice(2);
            const month = (rawDate.getMonth() + 1).toString().padStart(2, '0');
            const date = rawDate.getDate().toString().padStart(2, '0');
            const createdAt = `${year}-${month}-${date}`;
            return { ...item, createdAt };
          })
          this.loading=false
        }).catch(()=>{
          alert('알수 없는 에러가 발생하였습니다')
          this.loading=false
        })
      },
      loadBoardPost(type){
        if(type === 'qna'){
          this.loadQA()
        }else if(type === 'notice'){
          this.loadNotice()
        }
      },
      pageChanged(page){
        console.log('pageChanged',page)
        if(this.board_type==='qna'){
          const requestPage = {
            size : 10,
            page : page-1,
          }
          console.log('pageChanged 요청',requestPage)
          this.loading=true
          BoardApi.getNonMemberQuestions(requestPage).then(res=>{
            console.log('getNonMemberQuestions',res)
            this.page = res.page+1
            this.page_length=res.totalPages
            this.items=res.list.map((item) => {
              const time = item.registeredAt.replace(/-/g, "/")
              const rawDate = new Date(time);
              const year = rawDate.getFullYear().toString().slice(2);
              const month = (rawDate.getMonth() + 1).toString().padStart(2, '0');
              const date = rawDate.getDate().toString().padStart(2, '0');
              const registeredAt = `${year}-${month}-${date}`;
              return { ...item, registeredAt };
            })
            this.loading=false
          }).catch(()=>{
            this.loading=false
          })
        }else if(this.board_type==='notice'){
          const requestPage = {
            size : 10,
            page : page-1,
          }
          console.log('pageChanged 요청',requestPage)
          this.loading=true
          BoardApi.getAdminNoticesByPage(requestPage).then(res=>{
            console.log('getAdminNotices',res)
            this.page = res.page+1
            this.page_length=res.totalPages
            this.items=res.list.map((item) => {
              const time = item.createdAt.replace(/-/g, "/")
              const rawDate = new Date(time);
              const year = rawDate.getFullYear().toString().slice(2);
              const month = (rawDate.getMonth() + 1).toString().padStart(2, '0');
              const date = rawDate.getDate().toString().padStart(2, '0');
              const createdAt = `${year}-${month}-${date}`;
              return { ...item, createdAt };
            })
            this.loading=false
          }).catch(()=>{
            this.loading=false
          })
        }
      }
    }
}
</script>
<style>
.cs-main-contents {
  width: 77.6563vw;
  max-width: 1500px;
  margin: 0 auto;
}
.v-tabs,
.v-tabs > div,
.v-tabs > div > div,
.v-tabs > div > div > div {
  height: 80px;
  font-family: 'NEXON Lv1 Gothic';
}
.v-tabs-bar {
  border-bottom: 1px solid #DEDEDE;
}

.v-tabs .v-tab{
  font-size: 20px;
  font-weight: 400;
  margin: 0 45px;
}
.v-tabs .v-tab--active{
  font-weight: 700;
}

.v-data-table.cs_table > .v-data-table__wrapper > table > thead > tr > th {
  font-size: 18px;
  font-weight: 700;
  height: 77px;
  color: #232323;
  padding: 0;
}
.v-sheet > .v-data-table.cs_table > .v-data-table__wrapper > table > tbody > tr > td {
  border-bottom: 1px solid #dddddd;
  color: #515151;
  height: 55px;
  padding: 0px;
}


.v-sheet > .v-data-table.cs_table > .v-data-table__wrapper > table > tbody > tr > td:nth-child(2) {
  padding-right: 22px;
}

.v-data-table.cs_table > .v-data-table__wrapper > table > thead > tr > th:first-child,
.v-data-table.cs_table > .v-data-table__wrapper > table > tbody > tr > td:first-child {
  padding-left: 144px;
  box-sizing: content-box;
}


.cs_table table{
  border-top: 1px solid #323232;
  font-family: "NEXON Lv1 Gothic OTF";
}
.cs-main-contents .v-pagination {
  margin: 40px 0 141px;
}

.cs-main-contents .v-pagination button[aria-label="prev-label"],
.cs-main-contents .v-pagination button[aria-label="next-label"] {
  border-radius: 0;
  box-shadow: none;
  height: 4px;
}
.cs-main-contents button.v-pagination__item {
  width: 20px;
  min-width: 20px;
  height: 20px;
  font-size: 14px;
  box-shadow: none;
}

@media (max-width: 1920px) {
  .cs-main-contents {
    width: 77.656vw;
  }

  .v-data-table.cs_table > .v-data-table__wrapper > table > thead > tr > th:first-child,
  .v-data-table.cs_table > .v-data-table__wrapper > table > tbody > tr > td:first-child {
    padding-left: 7.500vw;
  }

  .cs-main-contents .v-pagination button[aria-label="prev-label"],
  .cs-main-contents .v-pagination button[aria-label="next-label"] {
    border-radius: 0;
    box-shadow: none;
    height: 4px;
  }

  .cs-main-contents button.v-pagination__item {
    width: 20px;
    min-width: 20px;
    height: 20px;
  }
}

@media (max-width: 960px) {
  .cs-main-contents {
  width: 91.667vw;
  }
  .v-tabs,
  .v-tabs > div,
  .v-tabs > div > div,
  .v-tabs > div > div > div {
    height: 60px;
  }

  .v-tabs .v-tab{
    font-size: 14px;
    margin: 0 22px;
  }

  .v-data-table.cs_table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 13px;
    height: 54px;
  }
  .v-sheet > .v-data-table.cs_table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 13px;
    height: 88px;
    padding: 0px;
  }

  .v-sheet > .v-data-table.cs_table > .v-data-table__wrapper > table > tbody > tr > td > div {
    margin-top: 8px;
    font-size: 11px;
    color: #969696;
  }

  .v-sheet > .v-data-table.cs_table > .v-data-table__wrapper > table > tbody > tr > td:last-child {
    padding: 0px;
  }

  .v-data-table.cs_table > .v-data-table__wrapper > table > thead > tr > th:first-child,
  .v-data-table.cs_table > .v-data-table__wrapper > table > tbody > tr > td:first-child {
    padding-left: 9px;
  }

  .cs-main-contents .v-pagination {
    margin: 30px 0 59px;
  }
}

</style>