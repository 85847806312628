<template>
    <v-sheet class="pb-16">
        <!-- PC -->
        <v-sheet >
            <v-sheet style="padding-bottom: 100px">
                <v-sheet class="mx-auto cost">
                    <p class="text-center cost-title">위셀페이 기본사양</p>
                    <v-sheet color="transparent">
                        <v-sheet color="transparent" width="100%">
                            <p class="mb-2 text-center cost-subtitle">서비스 사양</p>
                            <v-divider></v-divider>
                            <table class="cs">
                                <tr>
                                    <td><img src="../../assets/triangle.png" />상품 등록</td>
                                  <td>무제한 ( 조건에 따라 상이함 )</td>
                                </tr>
                                <tr>
                                    <td><img src="../../assets/triangle.png" />저장 공간</td>
                                    <td>무제한 ( 조건에 따라 상이함 )</td>
                                </tr>
                                <tr>
                                    <td><img src="../../assets/triangle.png" />트래픽 용량</td>
                                    <td>무제한 ( 조건에 따라 상이함 )</td>
                                </tr>
                                <tr>
                                    <td><img src="../../assets/triangle.png" />디자인</td>
                                    <td>기본 디자인 무료 제공 </td>
                                </tr>
                                <tr>
                                    <td><img src="../../assets/triangle.png" />PC / 모바일 쇼핑몰</td>
                                    <td>반응형 지원 및 무료 제공 </td>
                                </tr>
                                <tr>
                                    <td><img src="../../assets/triangle.png" />결제 수단</td>
                                  <td>신용카드 , 간편통합결제, 계좌이체, 가상계좌</td>
                                </tr>
                            </table>
                        </v-sheet>
                        <v-sheet width="100%" class="cost-table px-2">
                          <p class="mb-6 text-center cost-subtitle">서비스 이용요금</p>
                          <v-divider class="mt-1"></v-divider>
                          <p class="mt-15 text-center mx-auto">위셀페이 판매자는 <span>PG 가입비 무료</span>로 제공해 드리며, 타사 대비 매우 저렴한 수수료를 제공해 드립니다.</p>
                          <table class="adjustment">
                            <tr>
                              <td class="adj_color">결제수단</td>
                              <td>가맹점 구분</td>
                              <td>수수료<br v-if="$vuetify.breakpoint.xsOnly"/>(VAT별도)</td>
                            </tr>
                            <tr>
                              <td rowspan="5" class="adj_color">국내 신용카드</td>
                              <td>영세</td>
                              <td>1.6%</td>
                            </tr>
                            <tr>
                              <td>중소1</td>
                              <td>2.2%</td>
                            </tr>
                            <tr>
                              <td>중소2</td>
                              <td>2.35%</td>
                            </tr>
                            <tr>
                              <td>중소3</td>
                              <td>2.6%</td>
                            </tr>
                            <tr>
                              <td>일반</td>
                              <td>3.0%</td>
                            </tr>
                            <tr>
                              <td class="adj_color" id="mobile_adj">
                                <div>
                                  간편통합결제<br/>
                                  (카카오페이, 네이버페이, SSG, LPAY, PAYCO)
                                </div>
                              </td>
                              <td>일반</td>
                              <td>3.0%</td>
                            </tr>
                            <tr>
                              <td class="adj_color">계좌이체</td>
                              <td>-</td>
                              <td>2%<br v-if="$vuetify.breakpoint.xsOnly"/>(최저 200원)</td>
                            </tr>
                            <tr>
                              <td class="adj_color">가상계좌</td>
                              <td>-</td>
                              <td>350원/건</td>
                            </tr>
                          </table>
                        </v-sheet>
                    </v-sheet>
                </v-sheet>
                <v-sheet class="mx-auto return-payment px-2">
                    <p class="text-center cost-subtitle">정산</p>
                    <v-divider class="cost-divider"></v-divider>
                    <p class="text-center mx-auto text-info">위셀페이는 가입 즉시 모든 결제수단 이용 가능합니다.</p>
                  <div v-if="!$vuetify.breakpoint.xsOnly" class="px-2">
                    <table class="adjustment">
                      <tr>
                        <td class="adj_color">구분</td>
                        <td class="adj_color">신용카드</td>
                        <td class="adj_color">간편통합결제</td>
                        <td class="adj_color">계좌이체</td>
                        <td class="adj_color">가상계좌</td>
                      </tr>
                      <tr>
                        <td class="adj_color">정산주기</td>
                        <td>영업일 기준 D + 7일</td>
                        <td>영업일 기준 D + 7일</td>
                        <td>영업일 기준 D + 7일</td>
                        <td>영업일 기준 D + 7일</td>
                      </tr>
                    </table>
                  </div>
                  <table class="adjustment" v-if="$vuetify.breakpoint.xsOnly">
                    <tr>
                      <td class="adj_color">구분</td>
                      <td class="adj_color">정산주기</td>
                    </tr>
                    <tr>
                      <td class="adj_color">신용카드</td>
                      <td>영업일 기준 D + 7일</td>
                    </tr>
                    <tr>
                      <td class="adj_color">간편통합결제</td>
                      <td>영업일 기준 D + 7일</td>
                    </tr>
                    <tr>
                      <td class="adj_color">계좌이체</td>
                      <td>영업일 기준 D + 7일</td>
                    </tr>
                    <tr>
                      <td class="adj_color">가상계좌</td>
                      <td>영업일 기준 D + 7일</td>
                    </tr>
                  </table>
                  <v-sheet class="mt-4 px-2 note py-5 d-flex align-center justify-center">
                    <p>
                      ※ 기본 정산주기는 D+7일 입니다.<br />
                      ※ PG 계약시 가맹점 정보에 따라 정산주기는 변경될 수 있습니다.(월 4회)<br />
                      ※ 정산에 필요한 서류를 제출하지 않으시면 정산금을 지급받을 수 없습니다.
                    </p>
                  </v-sheet>
                </v-sheet>

            </v-sheet>
        </v-sheet>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        dialog:false
    })
}
</script>
<style lang="scss" scoped>
@import '../../styles/cost.scss';

</style>