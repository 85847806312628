<template>
    <v-sheet>
      <!-- PC -->
      <v-sheet class="introduce-product">
        <v-sheet class="first-sheet">
          <div>
            <div class="first-sheet-title">쉽고 빠른 <span>상품등록</span></div>
            <div v-if="$vuetify.breakpoint.smAndUp" class="first-sheet-subtitle">상품명, 판매가, 배송비만 등록하면 상품이 등록되고<br />주문서 링크가 만들어 집니다.<br />다양한 설정이 가능하여 자유롭게 상세정보를 설정 가능합니다.</div>
            <div v-if="!$vuetify.breakpoint.smAndUp" class="first-sheet-subtitle">상품명, 판매가, 배송비만 등록하면 상품이<br />등록되고 주문서 링크가 만들어 집니다.<br />다양한 설정이 가능하여 자유롭게 상세정보를<br/>설정 가능합니다.</div>
            <div class="explanation-contents">
              <div class="explanation-list">
                <div><span>1</span>상품명</div>
                <div><span>2</span>판매가</div>
                <div><span>3</span>배송비</div>
              </div>
              <img src="../../../assets/introduce/product/right_arrow.png" />
              <div class="complete-register">
                <div></div>
                <div></div>
                <div>상품 등록 완료</div>
              </div>
            </div>
          </div>
          <div class="explanation-image">
            <img src="../../../assets/introduce/product/desktop.png" />
            <img src="../../../assets/introduce/product/desktop_popup1.png" />
            <img src="../../../assets/introduce/product/desktop_popup2.png" />
            <img src="../../../assets/introduce/product/desktop_popup3.png" />
          </div>
        </v-sheet>
        <v-sheet class="second-sheet">
          <div>
            <div class="second-sheet-title">간편한 <span>상품 주소 공유</span></div>
            <div class="second-sheet-subtitle">클릭 한번으로 상품 주소를 복사하고<br v-if="$vuetify.breakpoint.xsOnly"/> 언제 <span>어디서든 빠르게 공유</span>가 가능합니다.<br />간단하게 SMS로 빠른 공유가 가능합니다.</div>
          </div>
          <div class="explanation-image">
            <img src="../../../assets/introduce/product/desktop2.png" />
            <img src="../../../assets/introduce/product/curved_arrow.png" />

            <img src="../../../assets/introduce/product/order_link.png" />
            <img src="../../../assets/introduce/product/rounded_right_arrow.png" />
            <img src="../../../assets/introduce/product/sms_management.png" />
            <img src="../../../assets/introduce/product/rounded_right_arrow.png" />
            <img src="../../../assets/introduce/product/mb_message.png" />
            <span>01</span>
            <span>02</span>
            <span>03</span>
          </div>
        </v-sheet>
        <v-sheet class="third-sheet">
          <div>
            <div class="third-sheet-title">간편하게 <span>상세페이지 편집</span></div>
            <div  class="third-sheet-subtitle">
              드래그 앤 드롭 몇 번 만으로<br v-if="$vuetify.breakpoint.xsOnly"/> 마치 전문가가 만든 듯 한 고객시선을 <br />
              <span>사로잡는 상세페이지</span>를 1분만에 완성할 수 있습니다.
            </div>
          </div>
          <div class="explanation-image">
            <img v-if="!$vuetify.breakpoint.xsOnly" src="../../../assets/introduce/product/clothes_detail.png" />
            <img v-if="$vuetify.breakpoint.xsOnly" src="../../../assets/introduce/product/clothes_detail_mobile.png" />
            <img src="../../../assets/introduce/product/clothes_detail_desktop.png" />
            <img src="../../../assets/introduce/product/clothes_detail_mb.png" />
          </div>
        </v-sheet>
        <v-sheet class="fourth-sheet">
          <div>
            <div class="fourth-sheet-title">상세하고 <span>다양한 옵션 등록</span></div>
            <div  class="fourth-sheet-subtitle">
              간단한 옵션 설정부터 다양한 옵션 조합의<br v-if="$vuetify.breakpoint.xsOnly" />
              복합 조합 옵션을 제공합니다.<br/>
              옵션별 재고와 판매금액을 설정할 수도 있습니다.<br/>
              직접입력 요청사항이나 추가상품 등록도 가능합니다.
            </div>
          </div>
          <div class="explanation-image">
            <img src="../../../assets/introduce/product/option_setting.png" />
            <img src="../../../assets/introduce/product/desktop_order.png" />
            <img src="../../../assets/introduce/product/option_detail.png" />
            <span>등록 click!</span>
          </div>
        </v-sheet>
        <v-sheet class="fifth-sheet">
          <div>
            <div class="fifth-sheet-title">더 간단하고 빠르게<br v-if="$vuetify.breakpoint.xsOnly"/> <span>결제링크 등록 및 공유</span></div>
            <div class="fifth-sheet-subtitle">
              배송이 필요 없는 상품 혹은 무형의 서비스 거래 시, 상품 등록 과정을 거치지 않고 주문서만 생성하여 결제할 수 있는 <span>수기결제 주문서</span>를 제공합니다.<br />
              상품 등록 과정 없이 결제가 가능하여 플리마켓, 오프라인 상점 등 어디서든 간편하게 사용 가능합니다.
            </div>
          </div>
          <div class="explanation-image">
            <img src="../../../assets/introduce/product/link_management.png" />
            <img src="../../../assets/introduce/product/rounded_right_arrow.png" />
            <img src="../../../assets/introduce/product/link_message.png" />
            <img src="../../../assets/introduce/product/rounded_right_arrow.png" />
            <img src="../../../assets/introduce/product/mb_link.png" />
            <div class="payment-info">
              <div></div>
              <div></div>
              <img src="../../../assets/introduce/product/payment_info.png" />
            </div>
            <div class="explanation-text">판매자</div>
            <div class="explanation-text">결제 URL > <span>결제요청</span></div>
            <div class="explanation-text">결제고객 > <span>즉시 결제</span></div>
          </div>
        </v-sheet>
        <v-sheet class="sixth-sheet">
          <div class="first-feature">
            <img src="../../../assets/introduce/product/order_sheet1.png" />
            <img src="../../../assets/introduce/product/order_sheet2.png" />
            <div class="first-explanation">
              <div class="feature-title">기능1</div>
              <div>
                기본 정보만으로도 결제 주문서가 생성됩니다.<br />
                추가 설정으로 필요하다면 이미지와 옵션을<br />
                설정하여 다양한 옵션 상품을 공유할 수 있습니다.
              </div>
            </div>
          </div>
          <div class="second-feature">
            <img src="../../../assets/introduce/product/order_list.png" />
            <img src="../../../assets/introduce/product/order_link_sms.png" />
            <img src="../../../assets/introduce/product/order_link2.png" />
            <img src="../../../assets/introduce/product/click_target.png" />
            <span>URL발송 click!</span>
            <div class="second-explanation">
              <div class="feature-title">기능2</div>
              <div>
                수기결제 주문서는 등록 후 <br />
                언제든 클릭 한번으로 주문서를 공유할 수 있습니다.<br />
                SMS 발송으로 빠르고 간편하게 공유할 수 있습니다.</div>
              <div></div>
            </div>
            </div>
        </v-sheet>
      </v-sheet>
    </v-sheet>
</template>
<script>

import MainFunctionView from "../main/MainFunctionView";
import MainFunctionView2 from "@/views/page/main/MainFunctionView2";

export default {
  components:{
    MainFunctionView2,
    MainFunctionView
  },
  data(){
    return {
      tabIndex:0
    }
  }
}
</script>
<style lang="scss" scoped
>
  // .first-sheet,
  // .second-sheet,
  // .third-sheet,
  // .fourth-sheet,
  // .fifth-sheet,
  // .sixt-sheet {
  //   width: 1920px;
  // }
  @import '../../../styles/introduce/product/product-first.scss';
  @import '../../../styles/introduce/product/product-second.scss';
  @import '../../../styles/introduce/product/product-third.scss';
  @import '../../../styles/introduce/product/product-fourth.scss';
  @import '../../../styles/introduce/product/product-fifth.scss';
  @import '../../../styles/introduce/product/product-sixth.scss';
</style>