<template>
    <v-sheet>
        <!-- PC -->
        <v-sheet class="introduce-shop">
        <v-sheet class="first-sheet">
          <div>
            <div class="first-sheet-title">다양한 쇼핑몰 기본설정</div>
            <div class="first-sheet-subtitle">
              판매자 쇼핑몰에 로고 이미지를 등록하거나<br />
              파비콘을 등록하여 자신만의 아이덴터티를 쇼핑몰에 부여할 수 있습니다.<br />
              쇼핑몰을 운영할 때 필수로 <span>필요한 정보를 쉽게<br v-if="$vuetify.breakpoint.xsOnly"/> 설정하고 쇼핑몰 화면에 적용</span>할 수 있습니다.
            </div>
          </div>
          <div class="explanation-image">
            <img src="../../../assets/introduce/shop/shop_laptop_setting.png" />
            <img src="../../../assets/introduce/shop/shop_mb_setting.png" />
            <img src="../../../assets/introduce/shop/up_right_arrow.png" />
            <span>적용 후 웹페이지</span>
          </div>
        </v-sheet>
        <v-sheet class="second-sheet">
          <div>
            <div class="second-sheet-title">쉽고 간편한 쇼핑몰 운영 설정</div>
            <div class="second-sheet-subtitle">
              회원/비회원 설정으로 주문 권한을<br v-if="$vuetify.breakpoint.xsOnly"/> 쉽게 설정할 수 있습니다.<br />
              필요한 메뉴를 간편하게 설정함으로써<br v-if="$vuetify.breakpoint.xsOnly"/> 쇼핑몰을 효율적으로 운영할 수 있습니다.
            </div>
          </div>
          <div class="explanation-image">
            <img src="../../../assets/introduce/shop/shop_laptop_management.png" />
            <img src="../../../assets/introduce/shop/shop_mb_management.png" />
            <img src="../../../assets/introduce/shop/shop_authorization.png" />
            <img v-if="!$vuetify.breakpoint.xsOnly" src="../../../assets/introduce/shop/click_target.png" />
            <img v-if="$vuetify.breakpoint.xsOnly" src="../../../assets/introduce/shop/mobile_click_target.png" />
          </div>
        </v-sheet>
        <v-sheet class="third-sheet">
          <div>
            <div class="third-sheet-title">다양한 결제 수단 지원 </div>
            <div class="third-sheet-subtitle">
              추가적인 설정이나 구축과정 없이<br v-if="$vuetify.breakpoint.xsOnly"/> 다양한 결제수단을 바로 사용할 수 있습니다.
            </div>
          </div>
          <div class="explanation-image">
            <span><img src="../../../assets/introduce/shop/isp_payment.png" />카드인증 결제<br />(ISP)</span>
            <span><img src="../../../assets/introduce/shop/simple_payment.png" />간편카드 결제</span>
            <span><img src="../../../assets/introduce/shop/card_payment.png" />일반카드 결제</span>
            <span><img src="../../../assets/introduce/shop/integration_payment.png" />
              <div>통합간편 결제<br /><div>네이버페이 / 카카오페이</div></div>
            </span>
            <span><img src="../../../assets/introduce/shop/transfer_payment.png" />
              계좌이체<br />
              /간편계좌이체<br />
              /가상계좌
            </span>
          </div>
        </v-sheet>
      </v-sheet>
    </v-sheet>
</template>
<script>

import MainFunctionView from "../main/MainFunctionView";
import MainFunctionView2 from "@/views/page/main/MainFunctionView2";

export default {
  components:{
    MainFunctionView2,
    MainFunctionView
  },
  data(){
    return {
      tabIndex:0
    }
  }
}
</script>
<style lang="scss" scoped>
  @import '../../../styles/introduce/shop/shop-first.scss';
  @import '../../../styles/introduce/shop/shop-second.scss';
  @import '../../../styles/introduce/shop/shop-third.scss';
</style>