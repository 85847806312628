<template>
  <div class="cs-info-header">
    <div v-if="boardType ==='notice'">
      <div class="cs-title">공지사항</div>
      <p>
        공지게시판입니다.
      </p>
    </div>
    <div v-if="boardType ==='qna'">
      <div class="cs-title">Q&A</div>
      <p>
        문의 게시판입니다. 다양한 문의를 해주세요.<br>
        버그/오류 리포트나 필요 기능에 대해서 요청을 해주셔도 됩니다. 개발 부서에서 적극 검토하여 빠르게 대응해 드립니다.
      </p>
      <div v-if="boardType==='qna'" class="question-button">
        <v-btn tile depressed color="#00ABA9" to="/main/qa/post">질문하기</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "cs_info",
  props:{
    boardType:String
  }
}
</script>

<style scoped>
  div {
   font-family: 'NEXON Lv1 Gothic';
   color: #424242;
  }

  .cs-info-header {
    margin-top: 46px;
  }
  div.cs-title {
    font-size: 34px;
    text-align: center;
  }
  .cs-info-header p {
    font-size: 16px;
    margin: 40px 0 36px 7.500vw;
  }

  .question-button {
    margin: 4px 152px 16px auto;
    text-align: right;
  }
  .cs-info-header > div > div.question-button > a {
    width: 142px;
    height: 42px;
    font-weight: 700;
    font-size: 18px;
    color: #FFF;
    font-family: 'Noto Sans CJK KR';
  }
  @media (max-width: 960px) {
    .cs-info-header {
      margin-top: 32px;
    }
    div.cs-title {
      font-size: 24px;
    }
    .cs-info-header p {
      font-size: 13px;
      margin: 22px 0 19px 15px;
    }

    .question-button {
      margin: 11px 15px 19px auto;
    }
    .cs-info-header > div > div.question-button > a {
      width: 118px;
      height: 42px;
      font-size: 14px;
    }

  }

</style>