<template>
  <div>
    <v-sheet class="pb-12">
      <v-sheet class="mx-auto cs-faq">
        <div class="faq-title">FAQ</div>
        <v-divider color="#424242"></v-divider>
        <v-sheet>
          <div class="d-flex justify-start align-center" style="width: 100%" v-if="$vuetify.breakpoint.smAndUp">
            <div class="faq-count pl-2">
              <span>전체</span>
              <span class="ml-2">{{page}}/{{page_length}}</span>
            </div>
            <div style="width: 100% ;" class="d-flex justify-end  align-center ">
              <div style="max-width: 500px"  class="d-flex justify-end  align-center pr-5">
                <v-text-field
                    v-model="keyword"
                    class="mr-4"
                    placeholder="검색어를 입력해주세요"
                ></v-text-field>
                <v-btn tile depressed color="#00ABA9" dark class="search-button"
                       @click="searchByKeyword"
                >검색</v-btn>
              </div>
            </div>
          </div>
          <div v-else>
            <div style="width: 100% ;" class="d-flex flex-column">
              <div class="d-flex justify-end  align-center px-2 pt-4">
                <v-text-field
                    v-model="keyword"
                    class="mr-4"
                    placeholder="검색어를 입력해주세요"
                ></v-text-field>
                <v-btn tile depressed color="#00ABA9" dark class="search-button"
                       @click="searchByKeyword"
                >검색</v-btn>
              </div>
            </div>
            <div class="faq-count pl-2">
              <span>전체</span>
              <span class="ml-2">{{page}}/{{page_length}}</span>
            </div>
          </div>
        </v-sheet>
        <v-sheet class="mx-auto faq-content">
          <v-expansion-panels class="pt-5">
            <v-expansion-panel
                v-for="(item,i) in items"
                :key="i"
            >
              <v-expansion-panel-header>
                <div class="d-flex justify-start">
                  <span class="text-h5 font-weight-bold">Q</span>
                  <div class="ml-7 d-flex align-center">{{item.title}}</div>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="d-flex justify-start align-start">
                  <span class="text-h5 font-weight-bold">A</span>
                  <div class="ml-7 d-flex align-center" style="overflow-x: auto">
                    <div v-html="item.content">

                    </div>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <!-- 페이지 -->
          <v-pagination
              class="my-6"
              color="#222222"
              circle
              next-aria-label="next-label"
              previous-aria-label="prev-label"
              v-model="page"
              :length="page_length"
              @input="pageChanged"
          ></v-pagination>
        </v-sheet>
      </v-sheet>
    </v-sheet>
  </div>
</template>

<script>
const {BoardApi} = require("@/api/BoardApi");

export default {
  name: "CsFaq",
  created() {
    this.search()
  },
  data(){
    return {
      items:[],
      page: 1,
      page_length: 1,
      keyword:''
    }
  },
  methods:{
    search(){
      BoardApi.getAdminFAQs('').then(res =>{
        this.page = res.page + 1
        this.page_length=res.totalPages
        this.items=res.list
      })
    },
    searchByKeyword(){
      BoardApi.getAdminFAQs(this.keyword).then(res =>{
        this.page = res.page + 1
        this.page_length=res.totalPages
        this.items=res.list
        this.keyword=''
      })
    },
    pageChanged(page){
      const requestPage = {
        size : 10,
        page : page-1,
      }
      this.loading=true
      BoardApi.getAdminFAQsByPage(requestPage).then(res=>{
        this.page = res.page+1
        this.page_length=res.totalPages
        this.items=res.list
        this.loading=false
      }).catch(()=>{
        this.loading=false
      })
    },
  }

}
</script>

<style>

  .faq-count {
    min-width: 200px;
  }

  .cs-faq {
    font-family: 'NEXON Lv1 Gothic OTF';
    width: 900px;
  }
  .cs-faq .faq-title {
    font-size: 34px;
    text-align: center;
    margin: 46px 0 41px;
  }

  .faq-content {
    width: 900px;
    margin: 0 auto;
  }

  .faq-content .v-pagination button[aria-label="prev-label"],
  .faq-content .v-pagination button[aria-label="next-label"] {
    border-radius: 0;
    box-shadow: none;
    height: 4px;
  }

  .faq-content button.v-pagination__item {
    width: 20px;
    min-width: 20px;
    height: 20px;
    font-size: 14px;
    box-shadow: none;
  }


  @media screen and (max-width: 1920px) {
    .active

    .cs-faq {
      width: 75.365vw;
    }
    .cs-faq .faq-title {
      font-size: 34px;
      text-align: center;
      margin: 31px 0 31px;
    }

    .faq-content {
      max-width: 900px;
    }

    .faq-content v-pagination button[aria-label="prev-label"],
    .faq-content v-pagination button[aria-label="next-label"] {
      border-radius: 0;
      box-shadow: none;
      height: 4px;
    }

    .faq-content button.v-pagination__item {
      width: 20px;
      min-width: 20px;
      height: 20px;
    }

  }

  @media screen and (max-width: 960px) {
    .cs-faq {
      width: 91.667vw;
    }

    .faq-count {
      min-width: 100px;
    }

    .cs-faq .faq-title {
      font-size: 24px;
      text-align: center;
      margin: 26px 0 26px;
    }

    .faq-content {
      width: 91.667vw;
    }
  }

</style>
