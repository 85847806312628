<template>
  <div>
    <v-sheet>
      <v-row justify="center">
        <v-dialog
            v-model="dialog"
            persistent
            max-width="320"
        >
          <v-card class="qa-auth">
            <div class="qa-auth-title">
              QnA 글보기 인증
            </div>
            <v-sheet class="qa-auth-content">
              <span class="qa-auth-password">
                비밀번호
              </span>
              <v-text-field
                  class="qa-auth-password-input"
                  type="password"
                  required
                  v-model="password"
                  :error-messages="password_Errors"
              ></v-text-field>
            </v-sheet>
            <div class="qa-auth-buttons">
              <v-btn tile depressed class="cancel-button"
                  @click="dialog = false"
              >
                취소
              </v-btn>
              <v-btn tile depressed class="auth-button"
                  @click="auth"
              >
                인증
              </v-btn>
            </div>
          </v-card>
        </v-dialog>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required,  minLength, maxLength } from 'vuelidate/lib/validators'

const {isEmpty} = require("@/lib/CommUtil");
const {ValidateApi} = require("@/api/ValidateApi");

export default {
  name: "QnAAuth",
  mixins: [validationMixin],
  components:{

  },
  validations: {
    password: {
      required,
      minLength: minLength(6),
    },
  },
  computed:{
    password_Errors () {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      !this.$v.password.required && errors.push('비밀번호를 입력해주세요')
      !this.$v.password.minLength && errors.push('6자이여야합니다')
      return errors
    },
  },
  data () {
    return {
      dialog: false,
      password:'',
      resultCallback: null,
      sellerCellPhone:null,
      authSessionKey:null,
      id:null,
    }
  },
  methods:{
    open(id,callback){
      this.dialog=true
      this.id=id
      this.resultCallback= callback
    },
    auth(){
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
        alert('비밀번호를 입력해 주세요')
        return
      }

      const request = {
        questionId: this.id,
        password: this.password,
      }
      ValidateApi.authenticateNonMemberQuestion(request).then( res =>{
        console.log('authenticateNonMemberQuestion',res)
        if(res.resCode === '0000'){
          this.resultCallback({
            res:true,
            resData:res.data,
          })
        }else{
          alert(res.msg)
        }
      }).catch(()=>{
        alert('요청이 잘못되었습니다.')
        this.dialog=false
      })
    },
    close(){
      this.dialog=false
    }
  }

}
</script>

<style lang="scss" scoped>
  @import '../../../styles/qa_auth.scss';
</style>