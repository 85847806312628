<template>
    <v-sheet>
        <!-- PC -->
        <v-sheet class="cs-read mx-auto">
            <p class="cs-read-title">
                공지사항
            </p>
            <v-divider color="#323232"></v-divider>
            <v-sheet>
                <v-sheet class="cs-read-subtitle">
                    {{ selected.title }}
                </v-sheet>
                <v-divider color="#323232"></v-divider>
                <v-sheet class="cs-read-content" style="min-height: 500px">
                    <div v-html="selected.content">
                    </div>
                </v-sheet>
                <v-sheet class="cs-read-info">
                    <p class="text-subtitle-1 grey--text text--darken-2 font-weight-bold">{{selected.writer}}</p>
                    <p class="text-subtitle-1 grey--text text--darken-2 ">{{selected.createdAt}}</p>
                    <p v-if="selected.readCount" class="text-subtitle-1 grey--text text--darken-2">조회수 {{selected.readCount}}</p>
                </v-sheet>
            </v-sheet>
            <v-divider color="#323232"></v-divider>
            <div class="cs-read-buttons">
                <v-btn tile depressed :to="'/main/cs?type=notice'" class="cs-read-button">< 이전</v-btn>
<!--                <v-btn tile depressed :to="'/main/cs?type=notice'" class="cs-read-button">다음 ></v-btn>-->
                <!-- 이전과 다음 공지사항에 대한 api 필요 -->
            </div>
        </v-sheet>
    </v-sheet>
</template>
<script>

import {isEmpty} from "@/lib/CommUtil";
const {BoardApi} = require("@/api/BoardApi");

export default {
    data: () => ({
        board_type:"notice",

        items: [
            {id:10, title:'공지사항 10', writer:'운영자', content:'내용1', created: '2021년 12월 31일 오전 11:35', view_count:50},
            {id:9, title:'공지사항 9', writer:'운영자', content:'내용2', created: '2021년 12월 31일 오전 11:35', view_count:234},
            {id:8, title:'공지사항 8', writer:'운영자', content:'내용3', created: '2021년 12월 31일 오전 11:35', view_count:2342},
            {id:7, title:'공지사항 7', writer:'운영자', content:'내용4', created: '2021년 12월 31일 오전 11:35', view_count:32},
            {id:6, title:'공지사항 6', writer:'운영자', content:'내용5', created: '2021년 12월 31일 오전 11:35', view_count:30},
            {id:5, title:'공지사항 5', writer:'운영자', content:'내용6', created: '2021년 12월 31일 오전 11:35', view_count:60},
            {id:4, title:'공지사항 4', writer:'운영자', content:'내용7', created: '2021년 12월 31일 오전 11:35', view_count:57},
            {id:3, title:'공지사항 3', writer:'운영자', content:'내용8', created: '2021년 12월 31일 오전 11:35', view_count:11},
            {id:2, title:'공지사항 2', writer:'운영자', content:'내용9', created: '2021년 12월 31일 오전 11:35', view_count:1},
            {id:1, title:'공지사항 1', writer:'운영자', content:'내용10', created: '2021년 12월 31일 오전 11:35', view_count:0},
        ],

        selected: {
          seq:'',
          title:'',
          content:'',
          created:'',
          writer:'운영자',
        }
    }),
  created() {
      if(!isEmpty(this.$route.query.id)){
        BoardApi.getAdminNoticePost(this.$route.query.id).then(res =>{
          console.log(res)
          this.selected=res
        })
      }

  },
  mounted(){
        if(this.$route.query.type=="notice"){
            this.items=this.notice_items
            this.board_type="notice"
        }else if(this.$route.query.type=="qna"){
            this.items=this.qna_items
            this.board_type="qna"
        }

        let item = this.items.filter(e => e.id==this.$route.query.id)

        this.selected = item[0];
    },

    methods: {
        goToRead(item){
            this.$router.push('/page/cs_read?type='+this.board_type+'&id='+item.id)
        }
    }
}
</script>
<style lang="scss">
    @import '../../styles/cs_read.scss';
</style>