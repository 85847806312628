<template>
    <v-sheet>
        <!-- PC -->
        <v-sheet class="introduce-message">
        <v-sheet class="first-sheet">
          <div>
            <div class="first-sheet-title">쇼핑몰 운영에 필수인 <br v-if="$vuetify.breakpoint.xsOnly"/><span>메시지 관리</span></div>
            <div class="first-sheet-subtitle">
              구매 고객과 판매자 분들이 반드시 알아야 하는 정보를 SMS<br v-if="!$vuetify.breakpoint.xsOnly"/>
              문자와 알림톡으로 발송하는 서비스입니다. 
            </div>
          </div>
          <img class="dotted-arrow" src="../../../assets/introduce/message/long_dotted_arrow.png" />
          <div class="explanation-image">
            <span><img src="../../../assets/introduce/message/delivery_alert.png" /><div>구매 고객에게<br />주문, 배송 알림</div></span>
            <span><img src="../../../assets/introduce/message/order_complete_alert.png" /><div>무통장 주문,<br />주문 완료 알림</div></span>
            <span><img src="../../../assets/introduce/message/product_url_delivery.png" /><div>상품 URL 전달</div></span>
            <span><img src="../../../assets/introduce/message/chatting_with_member.png" /><div>회원/SNS셀러와<br />소통</div></span>
          </div>
        </v-sheet>
        <v-sheet class="second-sheet">
          <div>
            <div class="second-sheet-title">자동메시지 관리</div>
            <div class="second-sheet-subtitle">
              고객에게는 주문 완료, 상품 배송 등 주문 상태 메시지와<br v-if="!$vuetify.breakpoint.xsOnly"/>
              판매자에게는 주문 확인 메시지를 자동으로 발송합니다.
            </div>
          </div>
          <div v-if="!$vuetify.breakpoint.xsOnly" class="explanation-image">
            <img src="../../../assets/introduce/message/short_dotted_arrow.png" />
            <img src="../../../assets/introduce/message/short_dotted_arrow.png" />
            <img src="../../../assets/introduce/message/point_charge.png" />
            <img src="../../../assets/introduce/message/settings.png" />
            <img src="../../../assets/introduce/message/auto_alert.png" />
            <span>포인트 충전</span>
            <span>판매자 관리툴에서 자동발송 설정</span>
            <span>SMS/알림톡 자동 전송</span>
          </div>
          <img v-if="$vuetify.breakpoint.xsOnly" class="dotted-arrow" src="../../../assets/introduce/message/short_dotted_arrow.png" />
          <div v-if="$vuetify.breakpoint.xsOnly" class="explanation-image">
            <span><img src="../../../assets/introduce/message/point_charge.png" /><div>포인트 충전</div></span>
            <span><img src="../../../assets/introduce/message/settings.png" /><div>무통장 주문, <br />주문 완료 알림</div></span>
            <span><img src="../../../assets/introduce/message/auto_alert.png" /><div>SMS/알림톡 자동 전송</div></span>
          </div>
        </v-sheet>
        <v-sheet class="third-sheet">
          <div>
            <div class="third-sheet-title">저렴한 알림 메시지 비용</div>
            <div class="third-sheet-subtitle">
              위셀페이는 회원 가입 시 SMS 기준<br /> <span>15,000건</span>이 무료 충전됩니다.
            </div>
            <div class="third-sheet-subtitle">
              무통장입금, 주문완료, 입금확인, 상품배송, 주문취소에<br v-if="!$vuetify.breakpoint.xsOnly"/>
              대한 내용을 카카오톡 알림톡 혹은 문자를 이용하여<br v-if="!$vuetify.breakpoint.xsOnly"/>
              손쉽게 발송 가능합니다.
            </div>
            <div class="third-sheet-subtitle">
              카카오톡 알림톡을 이용할 경우 장문메시지<br />
              (1000자 이내)에도 <span>추가차감 없이</span> 보유충전건수<br v-if="!$vuetify.breakpoint.xsOnly"/>
              에서 1건만 차감됩니다.
            </div>
          </div>
          <div class="explanation-image">
            <img src="../../../assets/introduce/message/message_table.png" />
            <img src="../../../assets/introduce/message/sms_message.png" />
            <img src="../../../assets/introduce/message/kakao_message.png" />
          </div>
        </v-sheet>
        <v-sheet class="fourth-sheet">
          <div>
            <div class="fourth-sheet-title">
              자동메시지 관리 <br v-if="$vuetify.breakpoint.xsOnly"/>주요기능
            </div>
            <div class="first-feature">
              <img src="../../../assets/introduce/message/sending_message.png" />
              <div class="feature-explanation">
                <span class="feature">주요기능 1</span>
                <div class="feature-title">
                  발송 메시지 설정
                </div>
                <div class="feature-subtitle">
                  위셀페이는 회원 가입 시 SMS 기준<br v-if="$vuetify.breakpoint.xsOnly"/> <span>15,000건이 무료 충전</span>됩니다.
                </div>
                <div class="feature-subtitle">
                  무통장입금, 주문완료, 입금확인, 상품배송, 주문취소에 대한 내용을<br v-if="!$vuetify.breakpoint.xsOnly"/>
                  카카오톡 알림톡 혹은 문자를 이용하여 손쉽게 발송 가능합니다.
                </div>
                <div class="feature-subtitle">
                  카카오톡 알림톡을 이용할 경우 장문메시지(1000자 이내)에도<br v-if="!$vuetify.breakpoint.xsOnly"/>
                  추가차감 없이 <span>보유충전건수에서 1건</span>만 차감됩니다.
                </div>
              </div>
            </div>
            <div class="second-feature">
              <div class="feature-explanation">
                <span class="feature">주요기능 2</span>
                <div class="feature-title">
                  발송 내역 조회
                </div>
                <div class="feature-subtitle">
                  SMS/알림톡이 오류 없이 발송됐는지 확인<br />
                  할 수 있도록 내역조회가 가능합니다.
                </div>
              </div>
              <img src="../../../assets/introduce/message/message_history.png" />
            </div>
          </div>
        </v-sheet>
      </v-sheet>
    </v-sheet>
</template>
<script>

import MainFunctionView from "../main/MainFunctionView";
import MainFunctionView2 from "@/views/page/main/MainFunctionView2";

export default {
  components:{
    MainFunctionView2,
    MainFunctionView
  },
  data(){
    return {
      tabIndex:0
    }
  }
}
</script>
<style lang="scss" scoped>
  @import '../../../styles/introduce/message/message-first.scss';
  @import '../../../styles/introduce/message/message-second.scss';
  @import '../../../styles/introduce/message/message-third.scss';
  @import '../../../styles/introduce/message/message-fourth.scss';
</style>