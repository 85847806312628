<template>
    <v-sheet>
        <!-- PC -->
        <v-sheet class="introduce-member">
        <v-sheet class="first-sheet">
          <div>
            <div class="first-sheet-title"><span>회원관리와 혜택</span>을<br v-if="$vuetify.breakpoint.xsOnly"/> 관리해 드립니다.</div>
            <div class="first-sheet-subtitle">
              지속적인 매출 향상을 위해 신규 고객 유치와 기존 고객을 단골로 만드는 것은 중요합니다.<br />
              회원/혜택 관리를 활용하여 재구매 유도와 단골 고객을 만들어 보세요.
            </div>
          </div>
          <div class="explanation-image">
            <span><img src="../../../assets/introduce/member/rounded_member.png" />회원관리</span>
            <span><img src="../../../assets/introduce/member/rounded_member_rank.png" />회원등급관리</span>
            <span><img src="../../../assets/introduce/member/rounded_point.png" />포인트관리</span>
            <span><img src="../../../assets/introduce/member/rounded_coupon.png" />쿠폰관리</span>
          </div>
        </v-sheet>
        <v-sheet class="second-sheet">
          <div>
            <div class="second-sheet-title">쉽고 간편한 <br v-if="$vuetify.breakpoint.xsOnly"/><span>회원 및 등급 관리</span></div>
            <div class="second-sheet-subtitle">
              쇼핑몰을 통해 가입한 회원들을 판매자 관리자 툴에서 쉽게 관리하실 수 있습니다.<br />
              정책 설정부터 지급/사용 현황까지 상세하고 편리한 관리 화면이 제공됩니다.
            </div>
          </div>
          <div class="explanation-image">
            <img src="../../../assets/introduce/member/member_list.png" />
            <img src="../../../assets/introduce/member/member_list_setting.png" />
          </div>
        </v-sheet>
        <v-sheet class="third-sheet">
          <div>
            <div class="third-sheet-title"><span>판매 향상</span>을 위한<br v-if="$vuetify.breakpoint.xsOnly"/> 혜택 관리</div>
            <div class="third-sheet-subtitle">
              포인트 활용을 통해 구매 고객들의 재구매 유도와 우수한 구매 후기를 확보해 보세요.<br />
              방문 증가와 실제적인 구매 활성화를 만들어 갈 수 있습니다.<br v-if="!$vuetify.breakpoint.xsOnly"/>
              정책 설정부터 지급/사용 현황까지 상세하고 편리한 관리 화면이 제공됩니다.
            </div>
          </div>
          <div class="explanation-image">
            <img src="../../../assets/introduce/member/blue_rounded_1.png" />
            <img src="../../../assets/introduce/member/member_policy1.png" />
            <img src="../../../assets/introduce/member/blue_rounded_2.png" />
            <img src="../../../assets/introduce/member/member_policy2.png" />
          </div>
        </v-sheet>
        <v-sheet class="fourth-sheet">
          <div>
            <div class="fourth-sheet-title">
              편한 결제를 위한<br v-if="$vuetify.breakpoint.xsOnly" /> <span>간편카드결제 기능</span> 제공
            </div>
            <div class="fourth-sheet-subtitle">
              한번 카드를 등록하면 비번만으로 간편하게 결제를 하실 수 있습니다.<br />
              회원 분들에게 빠르고 편한 결제수단을 제공!
            </div>
          </div>
          <div class="explanation-image">
            <img v-if="!$vuetify.breakpoint.xsOnly" src="../../../assets/introduce/member/vertical_dotted_line.png" />
            <span>방법 1</span>
            <img src="../../../assets/introduce/member/simple_card_management.png" />
            <span>방법 2</span>
            <img src="../../../assets/introduce/member/simple_card_registration.png" />
            <span>방법 3</span>
            <img src="../../../assets/introduce/member/registered_card_list.png" />
            <span>방법 4</span>
            <img src="../../../assets/introduce/member/card_payment.png" />
            <img v-if="!$vuetify.breakpoint.xsOnly" src="../../../assets/introduce/member/broken_dotted_line.png" />
          </div>
        </v-sheet>
      </v-sheet>
    </v-sheet>
</template>
<script>

import MainFunctionView from "../main/MainFunctionView";
import MainFunctionView2 from "@/views/page/main/MainFunctionView2";

export default {
  components:{
    MainFunctionView2,
    MainFunctionView
  },
  data(){
    return {
      tabIndex:0
    }
  }
}
</script>
<style lang="scss" scoped>
  @import '../../../styles/introduce/member/member-first.scss';
  @import '../../../styles/introduce/member/member-second.scss';
  @import '../../../styles/introduce/member/member-third.scss';
  @import '../../../styles/introduce/member/member-fourth.scss';
</style>